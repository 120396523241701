import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";
import { ArcIconButton } from "@arc-web/components/react";

import { chatApi, RetrievalMode, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../auth/authConfig";

import { questionConfig } from "../../api";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: import.meta.env.VITE_APPLICATIONINSIGHTS_KEY,
        connectionString: import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTIONSTRING,
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin]
        // *** Add the Click Analytics plug-in. ***
        // extensions: [reactPlugin, clickPluginInstance],
        // extensionConfig: {
        //   [reactPlugin.identifier]: { history: browserHistory },
        // // *** Add the Click Analytics plug-in. ***
        //   [clickPluginInstance.identifier]: clickPluginConfig
        // }
    }
});
appInsights.loadAppInsights();



let chatSessionID = 0;

// Function to generate a random ID
function generateRandomId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(parseInt(localStorage.getItem("chat_retrieveCount") || "10"));
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [fullFileCitation, setFullFileCitation] = useState<boolean>(localStorage.getItem("chat_fullFileCitation") === "true");
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);

    // Initialize state with a random ID
    const [uniqueId, setUniqueId] = useState(generateRandomId());

    const [likeIndexes, setLikeIndexes] = useState<string[]>([]);
    const [dislikeIndexes, setDislikeIndexes] = useState<string[]>([]);
    const [activeFeedback, setActiveFeedback] = useState({ 0: { like: false, dislike: false } });

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    
    const msalInstance = new PublicClientApplication(msalConfig);
    const userName = msalInstance.getAllAccounts()[0].username;

    const [chatBoxText, setChatBoxText] = useState<string>("");
    const [landingText, setLandingText] = useState<string>("");


    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                shouldStream: shouldStream,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    retrievalMode: retrievalMode,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };

            // const msalInstance = new PublicClientApplication(msalConfig);
            // const userName = msalInstance.getAllAccounts()[0].username;

            const response = await chatApi(request);

            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                let answer: string = "";
                let askResponse: AskResponse = {} as AskResponse;
                for await (const event of readNDJSONStream(response.body)) {
                    if (event["data_points"]) {
                        askResponse = event;
                    } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                        answer += event["choices"][0]["delta"]["content"];
                        let latestResponse: AskResponse = { ...askResponse, answer: answer };

                        setIsLoading(false);
                        setAnswers([...answers, [question, latestResponse]]);
                    }
                }
                appInsights.trackEvent({
                    name: "request",
                    properties: {
                        project: import.meta.env.VITE_PROJECT_NAME,
                        user: userName,
                        chatSessionID: chatSessionID,
                        questionNo: request.history.length,
                        question: question,
                        answer: answer,
                        history: request.history
                    }
                });
            } else {
                const parsedResponse: AskResponse = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse]]);
                // questionNoIndex:  += 1;
                appInsights.trackEvent({
                    name: "request",
                    properties: {
                        project: import.meta.env.VITE_PROJECT_NAME,
                        user: userName,
                        chatSessionID: chatSessionID,
                        questionNo: request.history.length,
                        question: question,
                        answer: parsedResponse.answer,
                        history: request.history
                    }
                });
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFeedbackInsights = (feedbackstatus, index, uniqueId) => {
        // console.log(feedbackstatus)
        // console.log(index)
        // console.log(answers)
        // console.log(uniqueId)
        
        // debugger;
        var id;

        var answerArray = answers[index]; 

        const project = import.meta.env.VITE_PROJECT_NAME;
        const questionNo = answers.length;
        const question = answerArray[0];        
        const answer = answerArray[1].answer;
        const data_points = answerArray[1].data_points;
        const thoughts = answerArray[1].thoughts;
        
        // debugger;

        if (index){
            // console.log( 'value',index)
            id = index;            
        }
        else {
            // console.log('no value', index)
            id = "0";
        }
        console.log(id, 'id')
        if (feedbackstatus === 'like'){
            
            let newState = { ...activeFeedback };

            newState[index] = {
                like: !newState[index].like,
                dislike: false
            };

            setActiveFeedback(newState);

            if (id in likeIndexes){
                return
            }
            
            setLikeIndexes((likeIndexes) => [...likeIndexes, id])

            appInsights.trackEvent({
                name: "feedback",
                properties: {
                    project: project,
                    user: userName,
                    chatSessionID: chatSessionID,
                    questionNo: questionNo,
                    question: question,
                    answer: answer,                    
                    feedbackstatus : feedbackstatus,
                    data_points: data_points,
                    thoughts: thoughts
                }
            });
        }
        else if (feedbackstatus === 'dislike'){
            
            let newState = { ...activeFeedback };

            newState[index] = {
                like: false,
                dislike: !newState[index].dislike
            };

            setActiveFeedback(newState);

            if (id in dislikeIndexes){
                return
            }
            
            setDislikeIndexes((dislikeIndexes) => [...dislikeIndexes, id])

            appInsights.trackEvent({
                name: "feedback",
                properties: {
                    project: project,
                    user: userName,
                    chatSessionID: chatSessionID,
                    questionNo: questionNo,
                    question: question,
                    answer: answer,                    
                    feedbackstatus : feedbackstatus,
                    data_points: data_points,
                    thoughts: thoughts
                }
            });            
        }        
        // console.log(answers[index]);
        // console.log(likeIndexes);
        // console.log(dislikeIndexes);       
        
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        chatSessionID += 1;
        setUniqueId(generateRandomId());        
        setActiveFeedback({ 0: { like: false, dislike: false } })
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        localStorage.setItem("chat_retrieveCount", newValue || "10");
        setRetrieveCount(parseInt(newValue || "10"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const handleActiveFeedback = (index, feedbackStatus) => {
        if (index === null || index === undefined) {
            return false;
        }

        if (index in activeFeedback) {
            return feedbackStatus === "like" ? activeFeedback[index].like : activeFeedback[index].dislike;
        } else {
            setActiveFeedback({
                ...activeFeedback,
                [index]: { like: false, dislike: false }
            });
            return false;
        }
    };

    const getChatBoxText = async () => {
        const defaultChatBoxText = "Type a new question";
        const defaultLandingText = "Type a new question";
        
        

        const questionResponse = await questionConfig();
        // console.log(questionResponse)
        
        setChatBoxText(questionResponse.chatbox_text || defaultChatBoxText);
        setLandingText(questionResponse.landing_text || defaultLandingText);
        
    };
    getChatBoxText();
    var data_version = import.meta.env.VITE_DATA_VERSION
    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <SparkleFilled fontSize={"100px"} primaryFill={"rgba(230, 30, 40, 1)"} aria-hidden="true" aria-label="Chat logo" />
                            <h4 className={styles.chatEmptyStateTitle}>Chat with your project</h4>
                            <h2 style={{ marginBottom: '8px' }} className={styles.chatEmptyStateSubtitle}>Reference documents included:</h2>
                            <ol style={{ marginBottom: '8px', marginTop: '0' }}>                                
                                <li className={styles.chatEmptyStateContent}>SRS DMCA (last updated: 2025-01-06)</li>
                                <li className={styles.chatEmptyStateContent}>SRS DBA (last updated: 2025-01-20)</li>
                            </ol>
                            <p style={{ marginBottom: '8px', marginTop: '0' }}>Consult the <em><a href="https://ecwe.fuse.arup.com/modules/ecwe_gpt_knowledgebase">ECWE ProjectGPT Knowledge Base</a></em> for a full list of source documents.</p>
                            <h3 style={{ marginBottom: '8px' }} className={styles.chatEmptyStateSubtitle}>Make sure to:</h3>                            
                            <ol style={{ marginTop: '0' }}>                                
                                <li className={styles.chatEmptyStateContent}>Be critical and always check the sources.</li>
                                <li className={styles.chatEmptyStateContent}>Include context and remove ambiguity from your questions.</li>
                                <li className={styles.chatEmptyStateContent}>If you are not getting results on your first attempt, try rephrasing your question a different way.</li>
                                {/* <li className={styles.chatEmptyStateContent}>
                                    Check <em>User Guides / ProjectGPT</em> for more tips and elaborations.
                                </li> */}
                            </ol>
                            <h3 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h3>
                            <ExampleList onExampleClicked={onExampleClicked} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}                                            
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                    <div  >                                        
                                    <ArcIconButton
                                                name="like"
                                                label="Icon button"
                                                active={handleActiveFeedback(index, "like")}
                                                onClick={() => handleFeedbackInsights("like", index, uniqueId)}
                                            >
                                                Like
                                            </ArcIconButton>
                                            <ArcIconButton
                                                name="dislike"
                                                label="Icon button"
                                                active={handleActiveFeedback(index, "dislike")}
                                                onClick={() => handleFeedbackInsights("dislike", index, uniqueId)}
                                            >
                                                Dislike
                                            </ArcIconButton>                                        
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder={chatBoxText}
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                    <Dropdown
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                            { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                            { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={fullFileCitation}
                        label="Citation will link to the full document"
                        onChange={() => {
                            setFullFileCitation(!fullFileCitation);
                            localStorage.setItem("chat_fullFileCitation", (!fullFileCitation).toString());
                        }}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
